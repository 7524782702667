import gql from "graphql-tag";

import { CONTACT_INFORMATION_ATTRIBUTES } from "./sharedFragments";

// Will be built upon in future
export const PUBLIC_SHOP_INFO_ATTRIBUTES = gql`
    fragment publicShopResults on PublicShopInfo {
        id
        name
        publicId
    }
`;

export const SHOP_OVERRIDE_ATTRIBUTES = gql`
    fragment shopOverrideResults on ShopOverride {
        id
        shopId
        outOfStockProductIds
        hideStockProductIds
        outOfStockAddonNames
        hideStockAddonNames
        inventoryStock {
            refProductId
            quantity
        }
        __typename
    }
`;

export const SHOP_BY_ID_CONTACT_PERSON_ATTRIBUTES = gql`
    fragment contactPersonResults on ContactInformation {
        name
        email
        phoneNumber
        __typename
    }
`;
export const COMPANY_SHOPS_BILLINGINFO_ATTRIBUTES = gql`
    fragment shopBillingInfoResults on BillingInfo {
        billingAddressLine
        billingPhoneNo
        billingPostCode
        billingCity
        organisationNumber
        organisationName
        billingEmail
        invoiceEmail
        paymentAccount {
            paymentAccountType
            accountNumber
        }
        __typename
    }
`;

export const STAFF_MANAGEMENT_SYSTEM_ATTRIBUTES = gql`
    fragment staffManagementSystemResults on StaffManagementSystem {
        systemName
        computerName
        guid
        apiKey
        managementType
        lastExportDate
        __typename
    }
`;

export const THIRD_PARTY_DELIVERY_SERVICES_ATTRIBUTES = gql`
    fragment thirdPartyDeliveryServicesResults on ThirdPartyDeliveryService {
        thirdPartyDeliveryType
        externalVendorId
        externalChainCode
        externalShopId
        enableService
        orderKey
        __typename
    }
`;

export const SERVICE_FEE_ATTRIBUTES = gql`
    fragment serviceFeeResults on ServiceFee {
        amount
        vatRate
        __typename
    }
`;

const SHOP_TAGS_ATTRIBUTES = gql`
    fragment shopTagsResult on ShopTags {
        generated
        always
        never
        __typename
    }
`;

export const ONLINE_SHOP_SETTINGS_ATTRIBUTES = gql`
    fragment onlineSettingsResults on ShopOnlineSettings {
        commentPlaceholder
        alwaysAllowPostponePickupTime
        sendSmsNoticeForPickup
        qrEatingOptions
        instagram
        facebook
        commentToClient
        disableShopComments
        enableEatHere
        onlyAsap
        eatingOptions
        enableOnlineOrders
        expressOnlineOrderConfirmation
        expressOnlineTextColor
        onlineMenuIds
        expressOnlineMenuIds
        pauseOnlineOrders
        onlineOrderPickupTimes
        onlineOrderPickupTimesPostpone
        closeOnlineOrders
        customClosedMessage
        customOpenMessage
        useHomePage
        url
        restaurantOnlineOrderUrl
        mandatoryCommentPlaceholder
        enableMandatoryComment
        restaurantOffline
        paymentAccount {
            paymentAccountType
            accountNumber
            __typename
        }
        productCommentsEnabled
        alcoholicBeverages
        enableExpressOnline
        rushHour
        rushHourWaitMinutes
        qrOnlineAutoOrderConfirmation
        qrOnlineHideTips
        disableTableService
        defaultTip
        onlineControlUnitPublicPosId
        autoAcceptOnlineBasicOrders
        predefinedCompletionTime
        qrQuickServe {
            qrPickupOptionTableId
            qrQuickServeMessage
            enableExtraFieldOnlyForQuickServe
        }
        __typename
    }
`;

export const HOME_DELIVERY_SETTINGS_ATTRIBUTES = gql`
    fragment homeDeliverySettingsResults on ShopHomeDeliverySettings {
        enableHomeDelivery
        pauseHomeDelivery
        minAmountFreeDelivery
        minOrderTotalAmount
        postalCodePlaceholder
        thirdPartyDelivery {
            thirdPartyDeliveryType
            apiKey
        }
        deliveryAreas {
            name
            deliveryFee
            enabled
            postalCodeRanges {
                rangeStart
                rangeEnd
            }
            postalCodes
        }
        __typename
    }
`;

export const CATERING_SETTINGS_ATTRIBUTES = gql`
    fragment cateringSettingsResults on ShopCateringSettings {
        enableCatering
        acceptInvoicePayment
        emailToConfirm
        noticeHours
        thirdPartyDeliveryType
        minOrderDeliveryAmount
        minOrderPickupAmount
        noPickupHours
        timeToConfirm
        lastOrderDay {
            dayOfWeek
            stoppingHour
            date
        }
        customCateringDates {
            message
            days
        }
        customSpecificCateringDates {
            message
            days
        }
        deliveryAreas {
            name
            deliveryFee
            enabled
            customDates {
                days
                message
            }
            postalCodeRanges {
                rangeStart
                rangeEnd
            }
            postalCodes
        }
        __typename
    }
`;

export const SHOP_SETTINGS_ATTRIBUTES = gql`
    fragment settingsResults on ShopSettings {
        shopTables {
            id
            name
            tableId
            posId
        }
        kitchenPrinters {
            id
            name
            noCopies
            shopId
            splitOrderProduct
            hideContactInfo
            refProductCategoryIds
            thirdPartyDeliveryTypes
            printerModel
            eatingOptions
            tableToPrint
            statusesToPrint
        }
        enablePunchClock
        enableKitchenPrinterOnline
        enableKitchenPrinterPOS
        defaultBongPrinterModel
        kitchenPrinterCopies
        allowedPaymentMethods
        refProductCategoryIdsToExcludeInKDS
        refBundleIdsToExcludeInKDS
        showOnKDSWithinMinutesBeforePickup
        allowedNumberOfOrdersInKDSBatch
        dailyRoutineTime
        enableDoneCookingStatus
        enableDonePreppingStatus
        enablePickupTabInOrderDisplay
        enableKitchenDisplay
        enablePickUpScreenQrOnReceipt
        updateChangeNumber
        predefinedComments
        enableSIEFileGeneration
        startingOrderNo
        lockableParts
        lockableAccessCode
        enableStaffManagementService
        allowCommentsInExpressPOS
        separateKitchenOrderProducts
        partnerPromotionId
        enableAutoAcceptThirdPartyOrders
        predefinedThirdPartyCompletionTime
        tags {
            ... on ShopTags {
                ...shopTagsResult
            }
        }
        onlineSettings {
            ... on ShopOnlineSettings {
                ...onlineSettingsResults
            }
        }
        homeDeliverySettings {
            ... on ShopHomeDeliverySettings {
                ...homeDeliverySettingsResults
            }
        }
        cateringSettings {
            ... on ShopCateringSettings {
                ...cateringSettingsResults
            }
        }
        thirdPartyAccountingSettings {
            accountAuthenticationId
            accessToken
            refreshToken
        }
        staffManagementSystem {
            ... on StaffManagementSystem {
                ...staffManagementSystemResults
            }
        }
        pickupSettings {
            hiddenProducts
        }
        expressPOSSettings {
            expressPrimary
            expressSecondary
            advertisingImageUrl
        }
        topicId
        __typename
    }
    ${SHOP_TAGS_ATTRIBUTES}
    ${ONLINE_SHOP_SETTINGS_ATTRIBUTES}
    ${HOME_DELIVERY_SETTINGS_ATTRIBUTES}
    ${CATERING_SETTINGS_ATTRIBUTES}
    ${STAFF_MANAGEMENT_SYSTEM_ATTRIBUTES}
`;

export const SHOP_ATTRIBUTES = gql`
    fragment shopResults on Shop {
        id
        publicId
        companyId
        timezone
        name
        desc
        imageUrl
        bannerUrl
        menuIds
        contactPerson {
            ... on ContactInformation {
                ...contactPersonResults
            }
        }
        contactInformation {
            ... on ContactInformation {
                ...contactInformationResults
            }
        }
        activeHours {
            closed
            date
            dayOfWeek
            desc
            startingHour
            stoppingHour
        }
        organisationNumber
        companyId
        umbrellaShop
        qoplaCommentLogs {
            username
            addedAt
            qoplaComment
        }
        billingInfo {
            ... on BillingInfo {
                ...shopBillingInfoResults
            }
        }
        thirdPartyDeliveryServices {
            ... on ThirdPartyDeliveryService {
                ...thirdPartyDeliveryServicesResults
            }
        }
        settings {
            ... on ShopSettings {
                ...settingsResults
            }
        }
        serviceFee {
            ... on ServiceFee {
                ...serviceFeeResults
            }
        }
        __typename
    }
    ${SHOP_BY_ID_CONTACT_PERSON_ATTRIBUTES}
    ${CONTACT_INFORMATION_ATTRIBUTES}
    ${COMPANY_SHOPS_BILLINGINFO_ATTRIBUTES}
    ${THIRD_PARTY_DELIVERY_SERVICES_ATTRIBUTES}
    ${SHOP_SETTINGS_ATTRIBUTES}
    ${SERVICE_FEE_ATTRIBUTES}
`;
